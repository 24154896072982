document.addEventListener("DOMContentLoaded", () => {

    // // Button animation

    let notebooks = document.querySelectorAll('.js-notebook');

    if (notebooks.length > 0) {  
        notebooks.forEach(notebook => {
            let button = notebook.querySelector('.js-gateway-btn');
            let back = notebook.querySelector('.js-gateway-back');
            let editionBtn = notebook.querySelector('.js-edition-btn');
            let closeBtn = notebook.querySelector('.js-close-btn');
            let rowForm = notebook.querySelectorAll('.js-notebook-row');
            let removeButton = notebook.querySelector('.js-remove-result');
            let results = notebook.querySelector('.js-results');
    
            if (editionBtn) {  
                editionBtn.addEventListener('click', () => {
                    notebook.classList.add('open');

                    if (results) {
                        results.classList.add('none');
                    }
                });
            }

            if (closeBtn) {
                closeBtn.addEventListener('click', () => {
                    notebook.classList.remove('open');


                    if (results) {
                        results.classList.remove('none');
                    }
                });
            }

            if (button) {
                button.addEventListener('click', () => {
                    notebook.classList.add('is-add');
                    back.focus();
                });
            }

            if (back) {
                back.addEventListener('click', () => {
                    notebook.classList.remove('is-add');
                    button.focus();
                });
            }

            if (removeButton) {
                removeButton.addEventListener('click', () => {
                    notebook.classList.add('is-remove');
                    notebook.ontransitionend = () => {
                        notebook.remove();
                    };
                });
            }

            ///////

            let addRowButton = notebook.querySelector('.js-row-add');
            let rowContainer = notebook.querySelector('.js-rows-container');
            let rowScript = notebook.querySelector('.js-notebook-script');

            if (rowScript) {
                let rowTemplate = rowScript.innerHTML;
                let index = 1; 
                
                function attachRemoveListeners() {
                    const trashBtns = rowContainer.querySelectorAll('.js-trash-btn');
                    trashBtns.forEach(trashBtn => {
                        trashBtn.removeEventListener('click', handleTrashClick);
                        trashBtn.addEventListener('click', handleTrashClick);
                    });
                }
    
                function handleTrashClick(event) {
                    const row = event.currentTarget.closest('.js-notebook-row');
                    row.remove();
                }
    
                addRowButton.addEventListener('click', () => {
                    const rows = notebook.querySelectorAll('.js-notebook-row');
                    const lastRow = rows[rows.length - 1];
                    let dataIndex;
                    if (rows.length == 0) {
                        dataIndex = 1;
                    } else {
                        dataIndex = lastRow.getAttribute('data-index');
                    }
                    dataIndex = parseInt(dataIndex, 10);

                    let newRowHtml = rowTemplate.replace(/__name__/g, 'notebookRows[' + dataIndex + ']').replace(/__index__/g, dataIndex + 1);
                    rowContainer.insertAdjacentHTML('beforeend', newRowHtml);
                
                    dataIndex++;
                    
                    attachRemoveListeners();
                });
                
                if (rowForm) {
                    rowForm.forEach(row => {
                        let trashBtn = row.querySelector('.js-trash-btn');
        
                        trashBtn.addEventListener('click', () => {
                            row.remove()
                        })
                    });
                }
            }
            

        });
    }

    // Ajax

    function handleButtonClick(buttons, className, action) {
        buttons.forEach(function(button) {
            button.addEventListener('click', function(e) {
                let url = this.getAttribute('data-url');
                let parentNotebook = this.closest('.js-notebook');

                fetch(url)
                .then(response => {
                    // console.log(response);
                    if (response.ok) { 
                        return response.text(); 
                    } else {
                        throw new Error('Réponse incorrecte.');
                    }
                })
                .then(data => {
                    if (parentNotebook) {
                        // console.log(data);
                        if (action === 'add') {
                            parentNotebook.classList.add(className);
                        } else if (action === 'remove') {
                            parentNotebook.classList.remove(className);
                        }
                    }
                })
                .catch(error => {
                    // console.log(error);
                    if (parentNotebook) {
                        parentNotebook.classList.remove(className);
                    }
                });
            });
        });
    }

    let addButton = document.querySelectorAll('.js-add-button');
    handleButtonClick(addButton, 'is-add', 'add');

    let removeButton = document.querySelectorAll('.js-remove-button');
    handleButtonClick(removeButton, 'is-add', 'remove');

});
