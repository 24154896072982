document.addEventListener("DOMContentLoaded", () => {
    const copyButtons = document.querySelectorAll('.js-copy');

    if(copyButtons.length > 0) {
        copyButtons.forEach(button => {
            button.addEventListener('click', function() {
                let iconCopy = button.querySelector('.visible');
                let iconNone = button.querySelector('.none');

                iconCopy.classList.add('none');
                iconCopy.classList.remove('visible');
                iconNone.classList.add('visible');
                iconNone.classList.remove('none');

                const url = button.getAttribute('data-url');

                if (navigator.clipboard && window.isSecureContext) {
                    navigator.clipboard.writeText(url).catch(err => {
                        console.error('Erreur lors de la copie dans le presse-papiers:', err);
                    });
                } 
            });
        });
    }
});