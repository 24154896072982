document.addEventListener("DOMContentLoaded", () => {
    const stepForm = document.querySelector(".js-step-form");
    let stepValidButton = document.querySelector(".js-step-valid");

    const validateForm = () => {
        const isValid = stepForm.checkValidity();
        if (isValid) {
            stepValidButton.removeAttribute('aria-disabled');
        } else {
            stepValidButton.setAttribute('aria-disabled', 'true');
        }
    };

    if (stepForm) {
        validateForm();

        stepForm.addEventListener('change', validateForm);
    }
});
